import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import {
  feedWidgetStyleParams,
  getLayoutName,
  isLayoutSlider,
  PostListWidgetPaginationType,
  PostListWidgetSliderArrowsPosition,
  postListWidgetStyleParams,
  postListWidgetStyleParamsByDevice,
  POST_LIST_WIDGET_DEFAULTS,
  SECTION_POST_LIST,
} from '@wix/communities-blog-client-common';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import {
  useActions,
  useSelector,
} from '../../../common/components/runtime-context';
import Pagination from '../../../common/containers/pagination/pagination';

import useDeviceType from '../../../common/hooks/use-device-type';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import {
  getUseMobileDisplaySettings,
  getUseMobileLayoutSettings,
} from '../../../common/selectors/app-settings-selectors';
import {
  getShouldUsePostListMobileSliderArrowColor,
  getShouldUsePostListMobileSliderBorderStyles,
} from '../../../common/selectors/mobile-settings-selectors';
import { getPostListPosts } from '../../../common/selectors/post-selectors';
import { getIsEntityLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getPostCount } from '../../../common/store/post-count/post-count-selector';
import { getPostListLayoutType } from '../../selectors/post-list-layout-type';
import { getPostListWidgetPageSize } from '../../selectors/post-list-widget-page-size';
import NoPostsEmptyState from '../no-posts-empty-state';
import styles from './post-list.scss';

type OwnProps = {};

const canSeeMoreButton = () => false;

const PostList = (props: OwnProps) => {
  const [page, setPage] = useState(1);

  const actions = useActions();
  const state = usePostListState();
  const prevState = useRef(state);
  const layoutOptionsRef = useRef(state.layoutOptions);

  useEffect(() => {
    if (
      state.pageSize !== prevState.current.pageSize ||
      state.featuredOnly !== prevState.current.featuredOnly ||
      state.isPaginationEnabled !== prevState.current.isPaginationEnabled
    ) {
      prevState.current = state;
      setPage(1);
      actions.fetchPostListPostsPromisified(1);
    }
  }, [actions, state]);

  useEffect(() => {
    if (!isEqual(layoutOptionsRef.current, state.layoutOptions)) {
      layoutOptionsRef.current = state.layoutOptions;
      actions.fetchPostListPostsPromisified(page);
    }
  }, [state.layoutOptions, actions, page]);

  const handlePageClick = (event: { page: number }) => {
    setPage(event.page);
    actions.fetchPostListPostsPromisified(event.page);
  };

  const hasPosts = Boolean(state.posts.length);

  if (!state.isLoading && !hasPosts) {
    return <NoPostsEmptyState />;
  }

  const postListRootClassNames = classNames({
    'mobile-slider-arrow-color': state.shouldUsePostListMobileSliderArrowColor,
    'mobile-slider-border-styles':
      state.shouldUsePostListMobileSliderBorderStyles,
  });

  return (
    <div className={postListRootClassNames}>
      <PostListProGallery
        className={
          layoutOptionsRef.current.arrowsPosition ===
          PostListWidgetSliderArrowsPosition.OnGallery
            ? 'arrows-on-gallery'
            : ''
        }
        layoutName={state.layoutName}
        layoutType={state.layoutType}
        layoutDefaults={POST_LIST_WIDGET_DEFAULTS}
        domId={state.componentId}
        allPosts={state.posts}
        currentPagePosts={state.posts}
        isLoading={state.isLoading}
        showCreatePostAction={false}
        section={SECTION_POST_LIST}
        canSeeMoreButton={canSeeMoreButton}
        layoutOptions={layoutOptionsRef.current}
        hideCoverImage={state.hideCoverImage}
      />
      {state.isPaginationEnabled && (
        <Pagination
          useDescriptionColor={true}
          containerClassName={styles.paginationContainer}
          page={page}
          onChange={handlePageClick}
          pageSize={state.pageSize}
          section={SECTION_POST_LIST}
          entityCount={state.postCount}
          createPageUrl={() => {}}
          activeClass="blog-post-list-pagination-active"
        />
      )}
    </div>
  );
};

function usePostListState() {
  const deviceType = useDeviceType();

  return useSelector((state, host) => {
    const layoutType = getPostListLayoutType({
      state,
      isMobile: deviceType.isMobile,
    });

    const layoutName =
      getLayoutName(layoutType) || POST_LIST_WIDGET_DEFAULTS.layoutName;
    const paginationType = getAppSettingsValue({
      state,
      key: `style.numbers.${postListWidgetStyleParams.postListWidgetPaginationType.key}`,
      fallback: postListWidgetStyleParams.postListWidgetPaginationType
        .defaultValue as unknown as number,
    });
    const {
      sliderShowArrows,
      sliderArrowsSize,
      sliderAutoSlide,
      sliderPauseTime,
      sliderArrowsPosition,
      sliderArrowsColor,
      sliderLoop,
    } = getUseMobileLayoutSettings(state)
      ? postListWidgetStyleParamsByDevice.mobile
      : postListWidgetStyleParamsByDevice.desktop;

    return {
      isPaginationEnabled:
        !isLayoutSlider(layoutType) &&
        String(paginationType) !== PostListWidgetPaginationType.NumberOfPosts,
      postCount: getPostCount(state),
      posts: getPostListPosts(state),
      layoutType,
      layoutName,
      isLoading: getIsEntityLoading(state, 'postListPosts'),
      componentId: host.id,
      pageSize: getPostListWidgetPageSize(state),
      sliderArrowsPositions: sliderArrowsPosition.options,
      hideCoverImage: !getAppSettingsValue({
        state,
        key: `style.booleans.${
          getUseMobileDisplaySettings(state)
            ? feedWidgetStyleParams['blog-mobile-isCoverImageEnabled'].key
            : feedWidgetStyleParams['blog-isCoverImageEnabled'].key
        }`,
        fallback: true,
      }),
      layoutOptions: {
        showArrows: getAppSettingsValue({
          state,
          key: `style.booleans.${sliderShowArrows.key}`,
          fallback: sliderShowArrows.defaultValue,
        }),
        arrowsSize: getAppSettingsValue({
          state,
          key: `style.numbers.${sliderArrowsSize.key}`,
          fallback: sliderArrowsSize.defaultValue,
        }),
        autoSlide: getAppSettingsValue({
          state,
          key: `style.booleans.${sliderAutoSlide.key}`,
          fallback: sliderAutoSlide.defaultValue,
        }),
        pauseTime: getAppSettingsValue({
          state,
          key: `style.numbers.${sliderPauseTime.key}`,
          fallback: sliderPauseTime.defaultValue,
        }),
        arrowsPosition: getAppSettingsValue({
          state,
          key: `style.numbers.${sliderArrowsPosition.key}`,
          fallback: sliderArrowsPosition.defaultValue,
        }),
        arrowsColor: getAppSettingsValue({
          state,
          key: `style.colors.${sliderArrowsColor.key}`,
          fallback: sliderArrowsColor.defaultValue,
        }),
        loop: getAppSettingsValue({
          state,
          key: `style.booleans.${sliderLoop.key}`,
          fallback: sliderLoop.defaultValue,
        }),
      },
      shouldUsePostListMobileSliderArrowColor:
        getShouldUsePostListMobileSliderArrowColor(state),
      shouldUsePostListMobileSliderBorderStyles:
        getShouldUsePostListMobileSliderBorderStyles(state),
      featuredOnly: getAppSettingsValue({
        state,
        key: `style.booleans.${postListWidgetStyleParams.postListWidgetIsFeatured.key}`,
      }),
    };
  });
}

export default PostList;
